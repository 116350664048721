<template>
  <div class="about">
    <h1>定期作業完了書 出力</h1>
    <v-card class="pa-5" flat outlined>
      <v-autocomplete
        v-model="selecthospital"
        :items="hospitals"
        outlined
        label="病院選択"
        item-text="name"
        item-value="code"
        chips
      >
      </v-autocomplete>
      <v-row>
        <v-col cols="5">
          <DatePicker
            v-model="date"
            label="作業予定日FROM"
            prepend-inner-icon="mdi-calendar"
            type="day"
            outlined
          />
        </v-col>
        <v-col class="pa-8" cols="1"> <p class="range-text">〜</p></v-col>
        <v-col cols="5">
          <DatePicker
            v-model="dateto"
            label="作業予定日To"
            prepend-inner-icon="mdi-calendar"
            type="day"
            outlined
          />
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <Btn icon="send" color="success" @click="click('query')">取得</Btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="!!excelData && excelData.length" class="office mt-5">
      <v-toolbar color="primary">
        <v-spacer></v-spacer>
        <Btn icon="send" color="success" @click="excel()">出力</Btn>
      </v-toolbar>
      <template v-for="affiliation in affiliations">
        <table class="mt-4 mx-4" border="1" :key="affiliation.index">
          <thead>
            <tr>
              <th rowspan="1" class="fixed"></th>
              <th v-for="(cal, i) in schedule" :key="i">
                {{ cal.name }}
              </th>
            </tr>
          </thead>
          <template v-for="n in affiliation.scount">
            <template v-for="(packagerow, i) in affiliation.packages[n - 1]">
              <tr :key="i">
                <th v-if="packagerow.rowspan > 0" :rowspan="packagerow.rowspan">
                  <input
                    type="checkbox"
                    name="selectPackageCheckBox"
                    :id="affiliation.index + packagerow.index"
                    :aindex="affiliation.index"
                    :pindex="packagerow.index"
                    checked
                  />
                </th>
                <template v-for="(meisai, j) in packagerow.packagemeisais">
                  <td
                    :key="j"
                    v-if="meisai.rowspan > 0"
                    :rowspan="meisai.rowspan"
                    style="white-space: pre-line; word-wrap: break-word;"
                    :class="meisai.width"
                    :bgcolor="meisai.color"
                  >
                    {{ meisai.name }}
                  </td>
                </template>
              </tr>
            </template>
          </template>
        </table>
      </template>
    </v-card>
  </div>
</template>
<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";

import Api from "@/mixins/api";

const DATE = new Date();

export default {
  name: "ExcelTeikiSagyo",
  mixins: [Common, ShowDialogs, Api],
  components: {},
  data: () => ({
    date:
      DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + Number(DATE.getDate())).slice(-2),
    dateto:
      DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + Number(DATE.getDate())).slice(-2),
    selected: [],
    hospitals: [],
    selecthospital: { code: "", name: "" },
    schedule: [],
    excelData: []
  }),
  computed: {},
  methods: {
    async click(pathparam) {
      console.log("click", pathparam, this.date);

      const date = new Date(this.date);
      const dateto = new Date(this.dateto);
      const result = true;

      if (date > dateto) {
        alert(
          "日付の範囲が正しくありません。 From < To の関係にしてください。"
        );
        return;
      }

      if (result) {
        this.$loading();
        try {
          this.schedule = await this.getTableData(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
            dateto.getFullYear(),
            dateto.getMonth() + 1,
            dateto.getDate(),
            pathparam
          );
          this.tableTitle = this.format;
        } catch (e) {
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async excel() {
      console.log("excel:download", "start");
      const checkBoxEle = document.getElementsByName("selectPackageCheckBox");
      const packages = [];
      const outputExcelData = [];
      const indexMap = [];
      for (let i = 0; i < checkBoxEle.length; i++) {
        const element = checkBoxEle[i];
        if (element.checked) {
          const aindex = element.getAttribute("aindex");
          const pindex = element.getAttribute("pindex");
          console.log("affiliations c", aindex, this.affiliations[aindex]);
          console.log(
            "packages c",
            pindex,
            this.affiliations[aindex].packages[pindex]
          );
          packages.push(this.affiliations[aindex].packages[pindex]);

          const resultIndex = indexMap.findIndex(r => r.aindex === aindex);
          if (resultIndex != -1) {
            indexMap[resultIndex].pindexList.push(pindex);
          } else {
            indexMap.push({
              aindex: aindex,
              pindexList: [pindex]
            });
          }
        }
      }

      if (indexMap.length === 0) {
        alert("チェックボックスが選択されていません。");
        return;
      }

      const confirm = await this.$confirm(
        "定期作業完了書をダウンロードしますか？",
        "EXCEL出力"
      );
      if (!confirm) return;

      this.$loading();
      const date = new Date(this.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const dateto = new Date(this.dateto);
      const yearto = dateto.getFullYear();
      const monthto = dateto.getMonth() + 1;
      const dayto = dateto.getDate();

      for (let a = 0; a < indexMap.length; a++) {
        const outputPackages = [];
        const oupputkeyid = [];
        for (let p = 0; p < indexMap[a].pindexList.length; p++) {
          outputPackages.push(
            this.excelData[indexMap[a].aindex].details[
              indexMap[a].pindexList[p]
            ].packageNo
          );
          oupputkeyid.push(
            this.excelData[indexMap[a].aindex].details[
              indexMap[a].pindexList[p]
            ].keyid
          );
        }
        outputExcelData.push({
          hojinCd: this.excelData[indexMap[a].aindex].hojinCd,
          hospitalCd: this.excelData[indexMap[a].aindex].hospitalCd,
          hospitalName: this.excelData[indexMap[a].aindex].hospitalName,
          packageNos: outputPackages.join(","),
          yearMonthDay:
            year +
            "-" +
            ("00" + month).slice(-2) +
            "-" +
            ("00" + day).slice(-2),
          yearMonthDayTo:
            yearto +
            "-" +
            ("00" + monthto).slice(-2) +
            "-" +
            ("00" + dayto).slice(-2),
          keyid: oupputkeyid.join(",")
        });
      }

      try {
        const encode = encodeURI(JSON.stringify(outputExcelData));
        const result = await this.$downloadexcel(
          "excelteikisagyo/output",
          "records=" + encode
        );
        const url = URL.createObjectURL(result);
        const link = document.createElement("a");
        link.href = url;
        link.download = "定期作業完了書.zip";
        link.click();
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
        console.log("excel:download", "end");
      }
    },
    async getHospital() {
      console.log("getHospital", this.Paths.hpFilter);
      return await this.$get(this.Paths.hpFilter);
    },
    async getTableData(year, month, day, yearto, monthto, dayto, pathparam) {
      console.log("getTableData", year, month, day, pathparam);

      let allFlg = false;
      if (this.selecthospital == null) {
        //alert("病院を選択してください。");
        allFlg = true;
      } else if (this.selecthospital.code == "") {
        //alert("病院を選択してください。");
        allFlg = true;
      }

      this.affiliations = [];
      this.packages = [];
      let param = {
        affiliations: this.selecthospital,
        targetmonth:
          year + "-" + ("00" + month).slice(-2) + "-" + ("00" + day).slice(-2),
        targetmonthto:
          yearto +
          "-" +
          ("00" + monthto).slice(-2) +
          "-" +
          ("00" + dayto).slice(-2),
        scheduleflag: "1",
        unfinished: "0",
        select: "0"
      };

      if (allFlg) {
        param = {
          targetmonth:
            year +
            "-" +
            ("00" + month).slice(-2) +
            "-" +
            ("00" + day).slice(-2),
          targetmonthto:
            yearto +
            "-" +
            ("00" + monthto).slice(-2) +
            "-" +
            ("00" + dayto).slice(-2),
          scheduleflag: "1",
          unfinished: "0",
          select: "0"
        };
      }

      const encode = encodeURI(JSON.stringify(param));
      // API送信
      const result = await this.$get(
        "ExcelTeikiSagyo",
        pathparam + "=" + encode
      );

      if (result.hedder.length === 0) {
        alert("該当するデータがありません。");
      }

      if (result.length == 0) {
        await this.$warning("該当するデータがありません。", "シフトデータ取得");
      } else {
        return await this.setTableData(result);
      }
    },
    async setTableData(apidata) {
      console.log("setTableData", apidata);

      //データヘッダを作成
      const shedder = apidata.schedulehedder;

      const schedules = [];
      for (let i = 0; i < shedder.length; i++) {
        if (
          shedder[i].name != "物件名" &&
          shedder[i].name != "作業内容" &&
          shedder[i].name != "作業予定日" &&
          shedder[i].name != "開始時間" &&
          shedder[i].name != "終了時間" &&
          shedder[i].name != "作業担当者" &&
          shedder[i].name != "担当者" &&
          shedder[i].name != "フロア" &&
          shedder[i].name != "エリア" &&
          shedder[i].name != "作業箇所" &&
          shedder[i].name != "備考"
        )
          continue;

        schedules.push({
          index: Number(shedder[i].code),
          name: shedder[i].name
        });
      }

      const hedder = apidata.hedder;
      this.excelData = apidata.hedder;

      for (let i = 0; i < hedder.length; i++) {
        const affiliation = hedder[i];

        const packages = [];
        const rowscount = [0];
        const rowindex = [0];
        const border = [""];
        const border2 = [""];

        const bgcolor = ["white"];

        for (let a = 0; a < affiliation.details.length; a++) {
          const b = affiliation.details[a];
          rowscount[0] = rowscount[0] + b.packagemeisai.length;
        }

        for (let j = 0; j < affiliation.details.length; j++) {
          const c = affiliation.details[j];

          const packageList = [];
          const meisairowspan = c.packagemeisai.length;

          if (j % 2 == 0) {
            bgcolor[0] = "white";
          } else {
            bgcolor[0] = "ECEFF1";
          }

          for (let k = 0; k < c.packagemeisai.length; k++) {
            const s = c.packagemeisai[k];
            const packagemeisaiList = [];
            const stime = c.startTime.split(":");
            const etime = c.endTime.split(":");

            if (
              j + 1 < affiliation.details.length &&
              k + 1 == c.packagemeisai.length
            ) {
              border[0] = "-border";
            } else {
              border[0] = "";
            }
            if (j + 1 < affiliation.details.length) {
              border2[0] = "-border";
            } else {
              border2[0] = "";
            }

            //物件名～フロアまでは、固定
            //物件名
            if (k > 0) {
              packagemeisaiList.push({
                rowspan: 0,
                status: "",
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                width: "seven" + border2[0],
                color: "white"
              });
            } else {
              packagemeisaiList.push({
                rowspan: meisairowspan,
                status: "",
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                width: "seven" + border2[0],
                color: bgcolor[0]
              });
            }
            //作業内容
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.workpattern,
              width: "seven" + border[0],
              color: bgcolor[0]
            });
            //作業日時
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: c.scheduledate,
              width: "seven" + border[0],
              color: bgcolor[0]
            });
            //開始時刻
            if (stime.length > 1) {
              packagemeisaiList.push({
                rowspan: 1,
                status: "",
                code: "",
                sepalate: "",
                name: stime[0] + ":" + stime[1],
                width: "five" + border[0],
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: 1,
                status: "",
                code: "",
                sepalate: "",
                name: "",
                width: "five" + border[0],
                color: bgcolor[0]
              });
            }
            //終了時刻
            if (etime.length > 1) {
              packagemeisaiList.push({
                rowspan: 1,
                status: "",
                code: "",
                sepalate: "",
                name: etime[0] + ":" + etime[1],
                width: "five" + border[0],
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: 1,
                status: "",
                code: "",
                sepalate: "",
                name: "",
                width: "five" + border[0],
                color: bgcolor[0]
              });
            }
            //作業担当者
            const wtanto = [""];
            for (let l = 0; l < c.worktanto.length; l++) {
              if (l > 0) {
                wtanto[0] = wtanto[0] + "\n";
              }
              wtanto[0] = wtanto[0] + c.worktanto[l].username;
            }
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: wtanto[0],
              width: "six" + border[0],
              color: bgcolor[0]
            });
            //担当者
            const tanto = [""];
            for (let l = 0; l < c.tanto.length; l++) {
              if (l > 0) {
                tanto[0] = tanto[0] + "\n";
              }
              tanto[0] = tanto[0] + c.tanto[l].username;
            }
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: tanto[0],
              width: "six" + border[0],
              color: bgcolor[0]
            });
            //フロア
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.floor,
              width: "seven" + border[0],
              color: bgcolor[0]
            });
            //エリア
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.area,
              width: "seven" + border[0],
              color: bgcolor[0]
            });
            const warea = [""];
            const st = [""];
            const add = [""];
            for (let l = 0; l < s.workarea.length; l++) {
              if (l > 0) {
                warea[0] = warea[0] + ",";
                st[0] = st[0] + ",";
                add[0] = add[0] + ",";
              }
              warea[0] = warea[0] + s.workarea[l].workarea;
              st[0] = st[0] + s.workarea[l].displayflag;
              add[0] = add[0] + s.workarea[l].addflag;
            }
            //作業箇所
            packagemeisaiList.push({
              rowspan: 1,
              status: st[0],
              code: add[0],
              sepalate: "0",
              name: warea[0],
              width: "workarea" + border[0],
              color: bgcolor[0]
            });
            if (k > 0) {
              //備考
              packagemeisaiList.push({
                rowspan: 0,
                status: "",
                code: "",
                sepalate: "",
                name: c.biko,
                width: "fifteen" + border2[0],
                color: bgcolor[0]
              });
            } else {
              //備考
              packagemeisaiList.push({
                rowspan: meisairowspan,
                status: "",
                code: "",
                sepalate: "",
                name: c.biko,
                width: "fifteen" + border2[0],
                color: bgcolor[0]
              });
            }

            //以下の内容は、画面には表示させないがデータ処理場必須のため渡す(必須:rowspan=0)

            if (k == 0) {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: meisairowspan
              });
            } else {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: 0
              });
            }
            rowindex[0] = rowindex[0] + 1;
          }

          packages.push(packageList);
        }

        const scounts = packages.length;

        this.affiliations.push({
          hojinCd: affiliation.hojinCd,
          hospitalCd: affiliation.hospitalCd,
          hospitalName: affiliation.hospitalName,
          yearMonthDay: affiliation.yearMonthDay,
          index: i,
          packages: packages,
          scount: scounts
        });
      }

      const schedule = schedules;
      return schedule;
    },
    onChange(e) {
      console.log(e);
      this.selected = e.selectedRows;
    }
  },
  mounted() {
    console.log("mounted");
  },
  async created() {
    console.log("created", this.date);
    this.$loading();

    try {
      // await this.getUsers();
      // await this.getShozoku();
      // this.setdate();
    } finally {
      this.$unloading();
    }

    this.hospitals = await this.getHospital();
  }
};
</script>

<style lang="scss" scoped>
.office {
  table {
    //width: 99%;
    border-collapse: collapse;
    display: block;
    white-space: pre-line;
  }
}
.five {
  width: 5%;
}
.six {
  width: 6%;
}
.seven {
  width: 7%;
}
.fifteen {
  width: 15%;
}
.workarea {
  width: 28%;
}
.five-border {
  width: 5%;
  border-bottom: double black;
}
.six-border {
  width: 6%;
  border-bottom: double black;
}
.seven-border {
  width: 7%;
  border-bottom: double black;
}
.fifteen-border {
  width: 15%;
  border-bottom: double black;
}
.workarea-border {
  width: 28%;
  border-bottom: double black;
}
.bold-text {
  font-weight: bold;
}
.pulldown-z-index {
  z-index: 3;
}
.fixed {
  position: sticky;
  left: 0;
  color: #333;
  background-color: white;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
  }
}
.private {
  table {
    width: 100%;
  }
}
.person {
  width: 50px;
}
.person::after {
  content: "ms";
}
.shift-event {
  font-size: 12px;
}

.warning-col {
  background-color: #ffcc66;
}

.worktime-span {
  float: right;
  margin-right: 15px;
}

table th:first-child {
  border-radius: 5px 0 0 0;
}

table th:last-child {
  border-radius: 0 5px 0 0;
  border-right: px solid #3c6690;
}

table th {
  text-align: center;
  color: Black;
  background: linear-gradient(white, white);
  border-left: 1px double #3c6690;
  border-top: 1px solid #3c6690;
  border-bottom: 1px solid #3c6690;
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.3) inset;
  width: 2%;
  padding: 10px 0;
}

input[type="checkbox"] {
  transform: scale(1.5);
}
</style>
