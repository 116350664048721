<template>
  <div class="about">
    <h1>週間予定表 出力</h1>
    <v-card class="pa-5" flat outlined>
      <v-card-actions>
        <v-spacer></v-spacer>
        <Btn icon="send" color="success" @click="excel()">出力</Btn>
        <!--<v-btn class="" dark text outlined @click="excel()">Excel出力</v-btn>-->
      </v-card-actions>
    </v-card>
    <v-card class="pa-5" flat outlined>
      <v-row>
        <v-col colspan="12">
          <DatePicker
            v-model="dateFrom"
            label="対象年月日 FROM"
            prepend-inner-icon="mdi-calendar"
            type="day"
            outlined
          />
        </v-col>
        <div style="top: 0; bottom: 0; margin: auto;"><label>～</label></div>
        <v-col colspan="12">
          <DatePicker
            v-model="dateTo"
            label="対象年月日 TO"
            prepend-inner-icon="mdi-calendar"
            type="day"
            outlined
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";

import Api from "@/mixins/api";

const DATE = new Date();

export default {
  name: "ExcelSyukanYotei",
  mixins: [Common, ShowDialogs, Api],
  components: {},
  data: () => ({
    dateFrom:
      DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + Number(DATE.getDate())).slice(-2),
    dateTo:
      DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + Number(DATE.getDate())).slice(-2),
    excelData: []
  }),
  computed: {},
  methods: {
    async excel() {
      console.log("excel:download", "start");

      const dateFrom = new Date(this.dateFrom);
      const dateTo = new Date(this.dateTo);

      if (dateFrom > dateTo) {
        alert("FromにはToより前の日付を指定してください。");
        return;
      }
      const termDay = (dateTo - dateFrom) / 86400000 + 1;
      if (termDay > 40) {
        alert("From～Toの差日は40日以下にしてください。");
        return;
      }

      const confirm = await this.$confirm(
        "週間予定表をダウンロードしますか？",
        "EXCEL出力"
      );
      if (!confirm) return;
      this.$loading();
      const yearFrom = dateFrom.getFullYear();
      const monthFrom = dateFrom.getMonth() + 1;
      const dayFrom = dateFrom.getDate();
      const yearTo = dateTo.getFullYear();
      const monthTo = dateTo.getMonth() + 1;
      const dayTo = dateTo.getDate();

      try {
        const param = {
          //dateFrom: dateFrom,
          dateFrom:
            yearFrom +
            "-" +
            ("00" + monthFrom).slice(-2) +
            "-" +
            ("00" + dayFrom).slice(-2),
          dateTo:
            yearTo +
            "-" +
            ("00" + monthTo).slice(-2) +
            "-" +
            ("00" + dayTo).slice(-2)
        };

        const encode = encodeURI(JSON.stringify(param));

        const result = await this.$downloadexcel(
          "excelsyukanyotei/output",
          "query=" + encode
        );

        if (result.size == 0) {
          alert("該当するデータがありません。");
          return;
        }

        const url = URL.createObjectURL(result);
        console.log(result);
        const link = document.createElement("a");
        link.href = url;
        link.download = "週間予定表.zip";
        link.click();
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
        console.log("excel:download", "end");
      }
    }
  },
  mounted() {
    console.log("mounted");
  },
  async created() {
    console.log("created", this.date);
    this.$loading();

    try {
      ////await this.getUsers();
      //// await this.getShozoku();
      //this.setdate();
    } finally {
      this.$unloading();
    }

    //this.hospitals = await this.getHospital();
  }
};
</script>
